<template>
  <input
    :value="modelValue"
    :type="type"
    :autocomplete="autocomplete"
    class="font-sans rounded-lg border-0 transition-[box-shadow,background-color] duration-150 text-left w-full box-border appearance-none focus:shadow-focus focus:outline-none"
    :class="[colorClass, sizeClass, invalid && 'shadow-focus shadow-red-text']"
    @input="onInput"
    @focus="firstTouch"
    @keyup="$emit('keyup', $event)"
  />
</template>

<script lang="ts" setup>
  interface Props {
    type?: string
    autocomplete?: string
    size?: "medium" | "small" | "large"
    color?: "gray" | "white"
    invalid?: boolean
    modelValue?: string
  }

  const emit = defineEmits(["update:modelValue", "keyup"])
  const props = withDefaults(defineProps<Props>(), {
    type: "text",
    autocomplete: "on",
    size: "medium",
    color: "gray",
    invalid: false,
    modelValue: "",
  })

  const sizeClass = computed(() => {
    switch (props.size) {
      case "small":
        return ["text-sm", "h-8", "px-4"]
      case "medium":
        return ["text-md", "px-4", "h-11"]
      case "large":
        return ["text-md", "px-6", "h-12"]
    }
  })

  const colorClass = computed(() => {
    switch (props.color) {
      case "gray":
        return ["bg-gray-100"]
      case "white":
        return ["bg-white", "hover:bg-gray-tint"]
    }
  })

  watch(
    () => props.modelValue,
    (newValue) => {
      if (newValue === null) {
        untouched.value = true
      }
    },
  )

  const untouched = ref(true)

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement
    emit("update:modelValue", target.value)
  }
  const firstTouch = () => {
    untouched.value = false
  }
</script>
